<template>
  <div>
    <div v-if="!auctionifos">
      <notfound :checkdata="Loading"></notfound>
    </div>
    <div v-else>
      <div class="sp-area">
        <div class="container-fluid">
          <vue-easy-lightbox
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
          >
          </vue-easy-lightbox>
          <div class="sp-nav" style="padding-bottom: 50px; margin-top: 25px">
            <!-- <div style="text-align: right; margin: 0px 12px 6px 0px">
              <button class="btn btn-primary" style="" @click="goBack()">
                <i aria-hidden="true" class="fa fa-arrow-left"></i> Go Back
              </button>
            </div> -->
            <div class="row">
              <div class="col-lg-4">
                <div class="sp-img_area" style="padding-left: 20px">
                  <div class="mainImages">
                    <hooper group="group1">
                      <slide
                        v-for="(vehImage, index) in vehImageArry"
                        :key="index"
                      >
                        <button @click="showSingle(vehImage.imageurl)">
                          <img
                            v-if="vehImage.imageurl != null"
                            :src="path + vehImage.imageurl"
                            alt="Product Image"
                          />
                        </button>
                      </slide>
                    </hooper>
                    <hooper group="group1" :itemsToShow="4" :centerMode="false">
                      <slide
                        v-for="(vehImage, index) in vehImageArry"
                        :key="index"
                      >
                        <div class="thumbnaildetails">
                          <img
                            v-if="vehImage.imageurl != null"
                            :src="path + vehImage.imageurl"
                            alt="Product Image"
                          />
                        </div>
                      </slide>

                      <hooper-pagination
                        slot="hooper-addons"
                      ></hooper-pagination>
                    </hooper>
                  </div>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="sp-content">
                  <!-- <div class="sp-heading">
                    <h5>
                      <a href="#"
                        >{{ auctionifos.brandName }}
                        {{ auctionifos.modelName }} {{ auctionifos.manYear }}</a
                      >
                    </h5>
                  </div> -->
                  <!-- <div v-if="auctionifos.vehdescription != null">
                    <p>{{ auctionifos.vehdescription }}.</p>
                  </div> -->

                  <div class="row">
                    <div class="col-md-6">
                      <div class="card" style="margin-bottom: 15px">
                        <div style="margin: 20px">
                          <h5>
                            <a style="color: #312d92" href="#"
                              >{{ auctionifos.brandName }}
                              {{ auctionifos.modelName }}
                              {{ auctionifos.manYear }}</a
                            >
                          </h5>
                          <div class="tablerounededCorner">
                            <table class="table table-striped">
                              <tr>
                                <td class="head-t-color">Make</td>
                                <td>{{ auctionifos.brandName }}</td>
                              </tr>
                              <tr>
                                <td class="head-t-color">Model</td>
                                <td>{{ auctionifos.modelName }}</td>
                              </tr>
                              <tr>
                                <td class="head-t-color">Registration</td>
                                <td>
                                  {{ auctionifos.vehRegNo | truncate(5) }} ****
                                </td>
                              </tr>
                              <!-- <tr>
                                <td>Trim</td>
                                <td>N/A</td>
                              </tr> -->
                              <tr>
                                <td class="head-t-color">Body Type</td>
                                <td>{{ auctionifos.vehTypeName }}</td>
                              </tr>
                              <tr>
                                <td class="head-t-color">Location</td>
                                <td>{{ auctionifos.locName }}</td>
                              </tr>
                              <tr>
                                <td class="head-t-color">KMs Driven</td>
                                <td>{{ auctionifos.kmClocked }} KM</td>
                              </tr>
                              <tr>
                                <td class="head-t-color">Year Of Make</td>
                                <td>{{ auctionifos.manYear }}</td>
                              </tr>                              
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6" style="">
                      <div class="card" style="">
                        <div style="margin: 20px">
                          <h6>Bid Information</h6>
                          <div class="bid-info">
                            <div class="bid-info-left">Current Bid Amount</div>
                            <div
                              class="bid-info-right"
                              v-if="auctionifos.latestBidAmount == null"
                            >
                              ₹ 0
                            </div>
                            <div class="bid-info-right" v-else>
                              {{ auctionifos.latestBidAmount | currency }}
                            </div>
                          </div>
                          <div class="bid-info">
                            <div class="bid-info-left">Time Remaining</div>
                            <div class="bid-info-right">
                              <Countdown
                                :customeStyle="'display:flex ;'"
                                :deadline="auctionifos.actualMaturityDate"
                              ></Countdown>
                            </div>
                          </div>

                          <div class="bid-info">
                            <div class="bid-info-left">Current Bids</div>
                            <div class="bid-info-right">
                              {{ auctionifos.bidzCount }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section>
              <div class="card" style="margin-top: 20px">
                <div style="padding: 20px">
                  <h4>Vehicle Description</h4>
                  <div v-if="auctionifos.vehdescription != null">
                    <p>{{ auctionifos.vehdescription }}.</p>
                  </div>
                </div>
                <div style="padding: 0px 20px">
                  <!-- Tab links -->
                  <div class="tab">
                    <button
                      class="tablinks"
                      @click="openCity(event, 'options')"
                    >
                      Options and Features
                    </button>

                    <button
                      class="tablinks"
                      @click="openCity(event, 'technical')"
                    >
                      Technical Specification
                    </button>
                  </div>

                  <!-- Tab content -->
                  <div id="options" class="tabcontent">
                    <table class="table table-striped">
                      <tr>
                        <td class="head-t-color">Roof AC</td>
                        <td v-if="auctionifos.adjustableSteering == true">
                          YES
                        </td>
                        <td v-else-if="auctionifos.adjustableSteering == false">
                          NO
                        </td>
                        <td v-else>NA</td>
                      </tr>
                      <tr>
                        <td class="head-t-color">Alloy Wheels</td>
                        <td v-if="auctionifos.alloyWheels == true">YES</td>
                        <td v-else-if="auctionifos.alloyWheels == false">NO</td>
                        <td v-else>NA</td>
                      </tr>
                      <tr>
                        <td class="head-t-color">Anti Theft Device</td>
                        <td v-if="auctionifos.antiTheftDevice == true">YES</td>
                        <td v-else-if="auctionifos.antiTheftDevice == false">
                          NO
                        </td>
                        <td v-else>NA</td>
                      </tr>
                      <tr>
                        <td class="head-t-color">Power window</td>
                        <td v-if="auctionifos.auxCompatibility == true">YES</td>
                        <td v-else-if="auctionifos.auxCompatibility == false">
                          NO
                        </td>
                        <td v-else>NA</td>
                      </tr>
                      <tr>
                        <td class="head-t-color">Rear Wiper</td>
                        <td v-if="auctionifos.bluetooth == true">YES</td>
                        <td v-else-if="auctionifos.bluetooth == false">NO</td>
                        <td v-else>NA</td>
                      </tr>
                      <tr>
                        <td class="head-t-color">
                          Comprehensive Navigation System
                        </td>
                        <td v-if="auctionifos.navigationSystem == true">YES</td>
                        <td v-else-if="auctionifos.navigationSystem == false">
                          NO
                        </td>
                        <td v-else>NA</td>
                      </tr>
                      <tr>
                        <td class="head-t-color">Parking Sensors</td>
                        <td v-if="auctionifos.parkingSensors == true">YES</td>
                        <td v-else-if="auctionifos.parkingSensors == false">
                          NO
                        </td>
                        <td v-else>NA</td>
                      </tr>
                      <tr>
                        <td class="head-t-color">Power steering</td>
                        <td v-if="auctionifos.powerSteering == true">YES</td>
                        <td v-else-if="auctionifos.powerSteering == false">
                          NO
                        </td>
                        <td v-else>NA</td>
                      </tr>
                      <tr>
                        <td class="head-t-color">Music system</td>
                        <td v-if="auctionifos.FMRadio == true">YES</td>
                        <td v-else-if="auctionifos.FMRadio == false">NO</td>
                        <td v-else>NA</td>
                      </tr>
                      <tr>
                        <td class="head-t-color">Rear Parking Camera</td>
                        <td v-if="auctionifos.rearParkingCamera == true">
                          YES
                        </td>
                        <td v-else-if="auctionifos.rearParkingCamera == false">
                          NO
                        </td>
                        <td v-else>NA</td>
                      </tr>
                      <tr>
                        <td class="head-t-color">Sunroof</td>
                        <td v-if="auctionifos.sunroof == true">YES</td>
                        <td v-else-if="auctionifos.sunroof == false">NO</td>
                        <td v-else>NA</td>
                      </tr>
                      <tr>
                        <td class="head-t-color">Cruise control</td>
                        <td v-if="auctionifos.usb == true">YES</td>
                        <td v-else-if="auctionifos.usb == false">NO</td>
                        <td v-else>NA</td>
                      </tr>
                    </table>
                  </div>

                  <div id="technical" class="tabcontent" style="display: none">
                    <table class="table table-striped">
                      <tr>
                        <td vclass="head-t-color">ABS</td>
                        <td v-if="auctionifos.abs == true">YES</td>
                        <td v-else-if="auctionifos.abs == false">NO</td>
                        <td v-else>NA</td>
                      </tr>
                      <tr>
                        <td class="head-t-color">Transmission</td>
                        <td v-if="auctionifos.HP">{{ auctionifos.HP }}</td>
                        <td v-else>NA</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </section>
            <section
              style="padding: 40px 0px; background-color: white"
              class="autionlog-table"
            >
              <div v-if="!auctionlog.length"></div>

              <div v-else>
                <div class="container-fluid">
                  <h4>Auction Log</h4>
                  <table>
                    <thead>
                      <tr>
                        <!-- <th><label>Customer</label></th> -->

                        <th><label>Bid Amount</label></th>
                        <th><label>Bid Date</label></th>
                      </tr>
                    </thead>
                    <tbody v-for="(list, index) in auctionlog" :key="index">
                      <tr>
                        <!-- <td data-label="Customer">{{ list.custName }}</td> -->

                        <td data-label="Bid Amount">
                          {{ list.bidAmount | currency }}
                        </td>
                        <td data-label="Bid Date">
                          {{ list.bidDate | formatDate }} IST
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Config from "@/config";
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";
import userService from "@/services/user.service";
import moment from "moment";
// import userinformation from "@/models/localstorage";
let imag1 = "";
let imag2 = "";
let imag3 = "";
let imag4 = "";
export default {
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },

  data() {
    return {
      imgs: "", // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
      imag1: imag1,
      imag2: imag2,
      imag3: imag3,
      imag4: imag4,
      details: "",
      auctionifos: "",
      bid: "",
      message: "",
      selectedUser: "",
      userinfo: "",
      auctionlog: "",
      isBitEntryModelActive: false,
      timeIntervel: Config.RefreshAuctionPage,
      vehImageArry: [],
      path: Config.BASE_URL_IMG,
      Loading: true,
    };
  },
  created() {
    // console.log(this.$route.params.url_key);

    this.userinfo = this.currentUser;
    this.sellerId = this.userinfo[0].sellerId;
    this.timer = setInterval(this.refreshAuctionList, this.timeIntervel);
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  filters: {
    dateISO(value) {
      if (value) {
        var d = new Date(value);
        // d = d.toLocaleString("en-US", { timeZone: Config.CurrNttimezone });
        // var date = new Date(d);
        return d.toISOString();
      }
    },
    truncate: function (data, num) {
      const reqdString = data.split("").slice(0, num).join("");
      return reqdString;
    },
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("D-MMM-YYYY h:mm a");
      }
    },
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
  },
  mounted() {
    let id = this.$route.params.url_key;

    this.getauctionbyname(id);
  },
  props: ["changed_url"],
  watch: {
    "$route.query.search"() {
      let id = this.$route.params.url_key;
      this.getauctionbyname(id);
    },
  },
  methods: {
    goBack() {
      history.back();
    },
    refreshAuctionList() {
      let id = this.$route.params.url_key;
      if (this.isBitEntryModelActive == false) {
        this.getauctionbyname(id);
      }
    },
    gotologin() {
      this.$router.push("/").catch(() => {
        "login";
      });
    },
    sendInfo(auctionifos) {
      if (!this.userinfo) {
        this.gotologin();
      } else {
        this.selectedUser = auctionifos;
        this.isBitEntryModelActive = true;
      }
    },
    getauctionbyname(id) {


      let type = "";
      if (!id || id == "") {
        this.$router.push("/VechDetails").catch(() => {
          "auctionbyname";
        });
      }
      let aucType =  this.$route.params.aucType;
    
      if(aucType =="auctionHistory"){ 
        type ="auctionHistory";
      }else{
        type ="activeAuction";
      }
      userService
        .getauctionbyname(id, this.sellerId,type)
        .then((response) => {
          this.auctionifos = response.data.Data[0];
          let image1 = null;
          let image2 = null;
          let image3 = null;
          let image4 = null;
          let image5 = null;
          let image6 = null;
          let image7 = null;
          let image8 = null;
          let image9 = null;
          let image10 = null;
          let image11 = null;
          let image12 = null;
          let image13 = null;
          let image14 = null;
          let image15 = null;

          if (typeof this.auctionifos.vehImage1 != "undefined") {
            image1 = this.auctionifos.vehImage1;
          } //else{image1  = ""};
          if (typeof this.auctionifos.vehImage2 != "undefined") {
            image2 = this.auctionifos.vehImage2;
          } //else{image2  = ""};
          if (typeof this.auctionifos.vehImage3 != "undefined") {
            image3 = this.auctionifos.vehImage3;
          } //else{image3  = ""};
          if (typeof this.auctionifos.vehImage4 != "undefined") {
            image4 = this.auctionifos.vehImage4;
          } //else{image4  = ""};
          if (typeof this.auctionifos.vehImage5 != "undefined") {
            image5 = this.auctionifos.vehImage5;
          } //else{image5  = ""};
          if (typeof this.auctionifos.vehImage6 != "undefined") {
            image6 = this.auctionifos.vehImage6;
          } //else{image6  = ""};
          if (typeof this.auctionifos.vehImage7 != "undefined") {
            image7 = this.auctionifos.vehImage7;
          } //else{image7  = ""};
          if (typeof this.auctionifos.vehImage8 != "undefined") {
            image8 = this.auctionifos.vehImage8;
          } //else{image8  = ""};
          if (typeof this.auctionifos.vehImage9 != "undefined") {
            image9 = this.auctionifos.vehImage9;
          } //else{image9  = ""};
          if (typeof this.auctionifos.vehImage10 != "undefined") {
            image10 = this.auctionifos.vehImage10;
          } //else{image10 = ""};
          if (typeof this.auctionifos.vehImage11 != "undefined") {
            image11 = this.auctionifos.vehImage11;
          } //else{image11 = ""};
          if (typeof this.auctionifos.vehImage12 != "undefined") {
            image12 = this.auctionifos.vehImage12;
          } //else{image12 = ""};
          if (typeof this.auctionifos.vehImage13 != "undefined") {
            image13 = this.auctionifos.vehImage13;
          } //else{image13 = ""};
          if (typeof this.auctionifos.vehImage14 != "undefined") {
            image14 = this.auctionifos.vehImage14;
          } //else{image14 = ""};
          if (typeof this.auctionifos.vehImage15 != "undefined") {
            image15 = this.auctionifos.vehImage15;
          } //else{image15 = ""};

          // console.log(this.auctionifos);
          let imageArry = [
            { imageurl: image1 },
            { imageurl: image2 },
            { imageurl: image3 },
            { imageurl: image4 },
            { imageurl: image5 },
            { imageurl: image6 },
            { imageurl: image7 },
            { imageurl: image8 },
            { imageurl: image9 },
            { imageurl: image10 },
            { imageurl: image11 },
            { imageurl: image12 },
            { imageurl: image13 },
            { imageurl: image14 },
            { imageurl: image15 },
          ];

          this.vehImageArry = imageArry.filter(function (el) {
            return el.imageurl != null;
          });
          this.Loading = false;

          this.getauctionlogbyid(this.auctionifos.aucId);
        })
        .catch((e) => {
          console.log(e);
          this.Loading = false;         
        });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    getauctionlogbyid(aucId) {
      // console.log(aucId);
      if (!aucId || aucId == "") {
        this.$router.push("/VechDetails").catch(() => {
          "auctionbyname";
        });
      }
      userService
        .getauctionlogbyid(aucId)
        .then((response) => {
          this.auctionlog = response.data.Data;
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Vendor").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },

    showSingle(image) {
      this.imgs = this.path + image;

      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    openCity(evt, cityName) {
      // Declare all variables
      var i, tabcontent, tablinks;

      // Get all elements with class="tabcontent" and hide them
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }

      // Get all elements with class="tablinks" and remove the class "active"
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }

      // Show the current tab, and add an "active" class to the button that opened the tab
      document.getElementById(cityName).style.display = "block";
      evt.currentTarget.className += " active";
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>
<style scoped>
@media screen and (max-width: 600px) {
  table td {
    border-bottom: 1px solid #e1e5e9;
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #3e3e3e !important;
    font-weight: 600 !important;
  }
}
/* this is details tile section style can be used any where, conditions apply*/

.thumbnaildetails img {
  height: 80px;
  width: 110px;
  object-fit: cover;
}
.hooper {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.hooper-slide {
  flex-shrink: 0;

  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  object-fit: cover;
}
.subBtnzz {
  background-image: linear-gradient(to bottom right, #af0000, #ff3a3a);
  color: white;
  font-weight: 600;
}
.bidamountzz {
  font-size: 20px;
  font-weight: 600;
}
.heart-stroke {
  fill: none;
  stroke: #ddd;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}
.button-bidzzz.active .heart-stroke {
  opacity: 0;
}
.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}
.button-bidzzz.active .heart-full {
  opacity: 1;
}
.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
  animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
  animation: heart 0.35s;
}
.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
  animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}
@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

th label {
  margin-bottom: 0px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}
table tr {
  background-color: #fff;
  border: 1px solid #e1e5e9;
  padding: 0.35em;
  border-radius: 3px;
}
table thead tr:first-child {
  border: 1px solid #0094da;
}
table th,
table td {
  padding: 8px;
  text-align: left;
  color: #202020;
  font-size: 14px;
}

table td:nth-child(4) {
  font-size: 18px;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: #0094da;
  color: #fff;
}

table tbody tr td .btn-invoice {
  background: #0094da;
  color: #fff;
  font-size: 13px;
  padding: 10px 20px;
  border: 0;
  width: 100px;
  border-radius: 5px;
}

tbody tr:nth-child(even) {
  background-color: #eee;
}

tbody tr:nth-child(odd) {
  background-color: #fff;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }
  table caption {
    font-size: 1.3em;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }
  table tr {
    border-bottom: 3px solid #e1e5e9;
    display: block;
    margin-bottom: 0.625em;
  }
  table th,
  table td {
    padding: 0.625em;
  }
  table td {
    border-bottom: 1px solid #e1e5e9;
    display: block;
    font-size: 0.8em;
    text-align: center;
    color: #9da9b9;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    color: #656971;
  }
  table td:last-child {
    border-bottom: 0;
  }
  table td:nth-child(4) {
    font-size: 0.8em;
  }
}
/* Style the tab */
.tab {
  overflow: hidden;
  /* border: 1px solid #ccc;
  background-color: #f1f1f1; */
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: whitesmoke;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  border-radius: 25px;
  margin: 4px;

  /* background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s; */
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}
</style>
<style>
.countdown {
  display: flex;
}
.countdown :nth-child(2) {
  margin-left: 4px;
}
</style>
